<template>
  <q-layout>
    <q-page padding>
      <q-toolbar class="column">
        <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Faturamento Afiliado</q-toolbar-title>
        <q-breadcrumbs class="q-mr-auto q-ma-sm">
          <q-breadcrumbs-el label="Painel de Controle" to="/ " />
          <q-breadcrumbs-el label="Faturamento Afiliado" exact />
        </q-breadcrumbs>
      </q-toolbar>
      <q-separator spaced />

      <header-affiliate-billing @affiliateId="affiliateIdHeader" />
      <list-affiliate-billing :affiliateId="affiliateId" />
    </q-page>
  </q-layout>
</template>

<script>
import HeaderAffiliateBilling from '../../components/financial/HeaderAffiliateBilling.vue';
import ListAffiliateBilling from '../../components/financial/ListAffiliateBilling.vue';

export default {
  name: 'AffiliateBilling',
  components: {
    HeaderAffiliateBilling,
    ListAffiliateBilling
  },
  data() {
    return {
      affiliateId: null
    };
  },

  methods: {
    affiliateIdHeader(payload) {
      this.affiliateId = payload;
    }
  }
};
</script>
