<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Faturas</q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <select-request
            :v-model="params.affiliate"
            label="Nome do afiliado"
            outlined
            endpoint="/redirect?app=AFFILIATE&path=/api/get/affiliate"
            option-label="name"
            option-value="id"
            clearable
            @update="updateAffiliateSelect"
          />
        </div>
        <div class="col-6">
          <q-input
            outlined
            label="ID do afiliado"
            :disable="disable_affiliateId"
            v-model="params.affiliateId"
            type="number"
          />
        </div>
        <div class="col-12 text-right">
          <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" @click="onFilterCampaigns" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';

export default {
  name: 'HeaderAffiliateBilling',
  mixins: [FinancialMixin],
  components: { SelectRequest },
  data() {
    return {
      filter: true,
      disable_affiliateId: false,
      params: {
        affiliate: null,
        affiliateId: null
      }
    };
  },
  methods: {
    updateAffiliateSelect(value) {
      if (value) {
        this.disable_affiliateId = true;
        this.params.affiliateId = value.id;
        this.params.affiliate = value;
      } else {
        this.params.affiliateId = null;
        this.disable_affiliateId = false;
      }
    },

    clearFilter() {
      for (let i in this.params) this.params[i] = Array.isArray(this.params[i]) ? [] : null;
      this.getAffiliateBillings();
    },

    onFilterCampaigns() {
      this.getAffiliateBillings(this.params.affiliateId);
      this.$emit('affiliateId', this.params.affiliateId);
    }
  }
};
</script>
